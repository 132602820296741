import React, { useState, useEffect } from 'react';
import './ssoRedirect.css';
import config from '../../config';
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../authConfig";
import { MS_CONSOLE_TITLE } from '../utils/Constants';

const BASE_URL = config.ssoRedirect;

export const SsoRedirect = (props) => {
  document.body.style.backgroundImage = "none";
  document.body.style.backgroundRepeat = "no-repeat";
  const [appaccesslink, setappAccessLink] = useState("");
  const [appadditionalaccesslink, setappAdditionalAccessLink] = useState("");
  const [prodBannerMessage, setprodContent] = useState("");
  const [nonprodBannerMessage, setContentfirst] = useState("");
  const [prodBannerMessages, setProdMessage] = useState("");
  const [showNonProdLink, setShowNonProdLink] = useState(false); // New state to control visibility
  const { instance, accounts } = useMsal(msalConfig);

  useEffect(() => {
    if (MS_CONSOLE_TITLE === "PROD") {
      setappAccessLink("https://pearsonnow.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=9336da491b8ea1d01c87ed79b04bcb85&sysparm_role_access=f303af0c1b25f5509c7cbb35464bcb2c");
      setprodContent("We've implemented a new authentication system. If you are an existing user, please log in with your Pearson account. If you're a new user, kindly");
      setappAdditionalAccessLink("https://pearsonnow.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=9336da491b8ea1d01c87ed79b04bcb85&sysparm_role_access=b9fa27b81bdcd2900d9cea01b24bcb3e");
      setProdMessage("OR if you're existing user, kindly fill out this form to request additional ");
      setContentfirst("If you encounter any issues during login, please email them to Media Server at <a href='mailto:HEDMediaserverengsupport@grp.pearson.com'>HED Mediaserverengsupport</a>");
      setShowNonProdLink(true); // Hide non-prod link in production
    } else {
      setappAccessLink("https://pearsonnow.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=d1a15db01bd981d0c2004337b04bcb5b&recordUrl=com.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1&sysparm_id=d1a15db01bd981d0c2004337b04bcb5b");
      setprodContent("We've implemented a new authentication system. If you are an existing user, please log in with your U42 PEDEV account. If you're a new user, kindly");
      setContentfirst("and reach out to the Media Server team to enable the access.");
      setShowNonProdLink(false); // Show non-prod link in non-production environment
    }
  }, []);

  async function myredirect() {
    try {
      // No active account, initiate login
      await instance.loginPopup(loginRequest);
    } catch (error) {
      console.error("Authentication error:", error);
    }
  }

  return (
    <div>
      <div className="ssoredirect-container">
        <div className="info-box">
          <span>{prodBannerMessage} </span>&nbsp;
          <a href={appaccesslink} target="_blank" rel="noreferrer">
            fill out this form to request access.
          </a>&nbsp;
          {showNonProdLink && (
          <>
          <span>{prodBannerMessages}</span>
          <a href={appadditionalaccesslink} target='_blank' rel="noreferrer">
              Application Folder Access.
          </a>&nbsp;
          </>
          )}
          <span dangerouslySetInnerHTML={{ __html: nonprodBannerMessage }}></span>
        </div>
        <h2>You need to login to access this content.</h2>
        <button className='btn-ssologin' onClick={myredirect} type='redirect'>
          Login
        </button>
      </div>
      {props.childern}
    </div>
  );
};

export function logout() {
  window.location.href = `${BASE_URL}/auth/json/sessions/?_action=logout`;
}
